var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"history-bots d-flex justify-center",class:_vm.$vuetify.breakpoint.smAndDown ? '' : 'pa-10'},[_c('div',{class:_vm.$vuetify.breakpoint.smAndDown ? 'w-100' : 'w-80'},[_c('Bots',{class:_vm.$vuetify.breakpoint.smAndDown ? 'd-none' : '',attrs:{"botHeader":'Select and Display chat histories'}}),_c('v-divider',{staticClass:"my-8",class:_vm.$vuetify.breakpoint.smAndDown ? 'd-none' : ''}),(_vm.isLoading)?_c('LoadingComponent'):_vm._e(),(!_vm.isLoading && _vm.userBotHistory && _vm.userBotHistory.length > 0)?_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4","lg":"4","sm":"12"}},[_c('h4',{staticClass:"mb-4"},[_vm._v("Select your conversation below")]),_c('div',{staticClass:"chat-messages pr-3",staticStyle:{"height":"50vh !important","overflow-y":"auto"}},_vm._l((_vm.userBotHistory),function(item,i){return _c('v-card',{key:i,staticClass:"mb-2",staticStyle:{"border":"1px solid"},style:({
                'border-color': _vm.isActive(item) ? '#4285f3' : '#E4E4E7',
              }),attrs:{"outlined":""},on:{"click":function($event){return _vm.openHistoryItem(item)}}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',[_c('div',{staticClass:"align-center justify-space-between pr-2"},[_c('v-card-title',{staticClass:"pa-2 pb-0 pl-3 pt-3",staticStyle:{"font-size":"11px","line-height":"1.2em"}},[_vm._v(" "+_vm._s(_vm._f("formattedDate")(item.updated_at)))])],1),_c('v-card-text',{staticClass:"font-weight-medium pt-0 px-3"},[_c('b',{staticStyle:{"color":"#4285f3"}},[_vm._v("User:")]),_vm._v(" "+_vm._s(item.title)+" ")])],1),_c('div',[_c('v-switch',{attrs:{"dense":""},on:{"click":function($event){$event.stopPropagation();return _vm.toggleConversation(item)}},model:{value:(item.toggle),callback:function ($$v) {_vm.$set(item, "toggle", $$v)},expression:"item.toggle"}})],1)])])}),1)]),_c('v-col',{attrs:{"cols":"12","md":"8","lg":"8","sm":"12"}},[_c('div',{staticClass:"d-flex align-start justify-space-between mb-4"},[_c('h4',{},[_vm._v("Chat conversation")]),_c('v-btn',{staticStyle:{"visibility":"hidden"},attrs:{"outlined":"","small":"","color":"green"}},[_vm._v("Download Messages")])],1),_c('div',{staticClass:"chat-messages pr-3",staticStyle:{"height":"50vh !important","overflow-y":"auto"}},[(_vm.isLoadingMessages)?_c('LoadingComponent'):_vm._e(),(
                !_vm.isLoading &&
                !_vm.isLoadingMessages &&
                _vm.userBotHistoryMessages &&
                _vm.userBotHistoryMessages.length > 0
              )?_c('div',_vm._l((_vm.userBotHistoryMessages),function(message,i){return _c('div',{key:i},[(message.role == 'user')?_c('myBubble',{staticClass:"mx-4",attrs:{"text":message.content,"activeBot":_vm.activeBot}}):_c('aiBubble',{staticClass:"mx-4",attrs:{"text":message.content,"activeBot":_vm.activeBot}})],1)}),0):_vm._e()],1)])],1)],1):_vm._e(),(!_vm.isLoading && _vm.userBotHistory.length <= 0)?_c('div',{staticClass:"w-100 d-flex justify-center align-center"},[_vm._m(0)]):_vm._e()],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('p',{staticClass:"mb-2 font-weight-bold"},[_vm._v("No data available.")])])}]

export { render, staticRenderFns }